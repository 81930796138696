import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Сервисный центр OMAR
			</title>
			<meta name={"description"} content={"Точный уход за автомобилем для каждой поездки"} />
			<meta property={"og:title"} content={"Главная | Сервисный центр OMAR"} />
			<meta property={"og:description"} content={"Точный уход за автомобилем для каждой поездки"} />
			<meta property={"og:image"} content={"https://lumidionsell.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://lumidionsell.com/img/3253523.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://lumidionsell.com/img/3253523.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://lumidionsell.com/img/3253523.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://lumidionsell.com/img/3253523.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://lumidionsell.com/img/3253523.png"} />
			<meta name={"msapplication-TileImage"} content={"https://lumidionsell.com/img/3253523.png"} />
			<meta name={"msapplication-TileColor"} content={"https://lumidionsell.com/img/3253523.png"} />
		</Helmet>
		<Components.ToluqyeHeader />
		<Section
			padding="140px 0 140px 0"
			background="#FFFFFF url(https://lumidionsell.com/img/1.jpg) 100% 0% /cover no-repeat scroll padding-box"
			min-height="70vh"
			md-padding="96px 0 70px 0"
			sm-padding="72px 0 70px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="80%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Сервисный центр OMAR
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--lead" lg-text-align="center">
				Откройте для себя первоклассный автосервис в сервисном центре OMAR, где мы стремимся улучшить качество вашего вождения. Мы понимаем, что ваш автомобиль играет важную роль в вашей повседневной жизни, поэтому мы предоставляем первоклассный сервис и уделяем тщательное внимание каждой детали. Будь то плановое техническое обслуживание, диагностика или сложный ремонт, наша опытная команда готова удовлетворить ваши потребности. В нашем центре передовые технологии сочетаются с проверенными методами, чтобы ваш автомобиль работал наилучшим образом. Посетите OMAR, чтобы получить надежный и комплексный уход за автомобилем в соответствии с вашим графиком и особыми требованиями вашего автомобиля.
				</Text>
				<Box display="flex" sm-flex-direction="column" sm-text-align="center">
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-light"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						hover-transition="border 0.2s ease-in-out 0s"
						hover-border-color="--color-orange"
						transition="border 0.2s ease-in-out 0s"
					>
						Посетите нас
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			/>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
						Почему стоит выбрать OMAR?
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Всесторонняя экспертиза: 
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Наши техники сертифицированы и обладают навыками работы со всеми основными марками и моделями автомобилей.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Скорость и точность:
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Мы оптимизируем наши процессы, чтобы предоставлять быстрые услуги без ущерба для качества.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Понятные цены: 
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Вы всегда будете знать, за что платите, благодаря нашей прозрачной модели ценообразования.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Ориентируемся на вас: 
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Мы заботимся о том, чтобы каждое посещение было удобным, эффективным и отвечало вашим автомобильным потребностям.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			text-align="center"
			padding="80px 0"
			sm-padding="40px 0"
			align-items="center"
			justify-content="center"
			display="flex"
		>
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			О сервисном центре OMAR
			</Text>
			<Text
				as="p"
				font="--lead"
				margin="20px 0 0 0"
				width="80%"
				text-align="center"
				align-self="center"
			>
				Сервисный центр OMAR был основан для обеспечения превосходного обслуживания автомобилей. Мы гордимся нашим современным оборудованием и командой квалифицированных техников с большим опытом работы в отрасли. Наша миссия заключается в предоставлении эффективных и высококачественных решений, учитывающих потребности каждого автомобиля. Интегрируя передовые технологии с проверенными методами, мы стремимся превзойти ожидания и обеспечить комплексный уход за автомобилем. В OMAR мы гарантируем, что каждый клиент уедет на своем автомобиле в оптимальном состоянии, готовым к дальней дороге.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box
					padding="10px"
					width="320px"
					height="504px"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						src="https://lumidionsell.com/img/2.jpg"
						width="auto"
						height="504px"
					/>
				</Box>
				<Box
					padding="10px"
					width="320px"
					height="504px"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image src="https://lumidionsell.com/img/3.jpg" height="504px" />
				</Box>
				<Box
					padding="10px"
					width="320px"
					height="504px"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image src="https://lumidionsell.com/img/4.jpg" height="504px" />
				</Box>
			</Box>
		</Section>
		<Components.ToluqyeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});